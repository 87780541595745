import currentPhase from './currentPhase';
import quote from './quote';
import user from './user';
import session from './session';
import { Models } from '@rematch/core';
import userInterface from './userInterface';

export interface RootModel extends Models<RootModel> {
  currentPhase: typeof currentPhase;
  userInterface: typeof userInterface;
  user: typeof user;
  quote: typeof quote;
  session: typeof session;
}

const model = { currentPhase, userInterface, quote, session, user };
export default model;
