import React from 'react';
import { Redirect } from 'react-router-dom';
import { findStateName } from 'src/lib/states';

import { useModel } from 'src/lib/hooks';
import products from 'src/products';

const Onboarding = () => {
  const { State, FormType } = useModel.quote();

  const stateName = findStateName(State)?.toLowerCase();
  const productName = FormType?.replace('-', '').toLowerCase();

  if (!stateName) {
    return <Redirect to="/" />;
  }

  const OnboardingContent =
    stateName && productName && products?.[stateName]?.[productName];

  if (!OnboardingContent) {
    return <Redirect to="/" />;
  }

  return <OnboardingContent />;
};

export default Onboarding;
