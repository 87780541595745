import merge from 'lodash.merge';

import { api } from 'src/lib/hooks/useApi/useApi';
import {
  windMitTransform,
  coveragesTransform,
  convertPropertyToNumber,
} from 'src/lib/transforms';
import { cleanEmpty } from 'src/lib/utils';
import { PRODUCTS } from 'src/products/products';
import { PAGES } from 'src/products/shared/constants';
import { Quote } from './model';
import {
  ApplyDiscountPayload,
  DeleteQuoteObjectsPayload,
  GetPolicyAttachmentPayload,
  GetPolicyNumberWithPolicyIdPayload,
  NewQuotePayload,
  PutPaymentPlanPayload,
  ResumePayload,
  SetPackagePayload,
  UpdateCoveragesAndDeductiblesPayload,
  UpdateOptionalCoveragePayload,
  UpdateQuotePayload,
  UploadAttachmentPayload,
  UploadWindMitFormPayload,
  UpsertAdditionalInterestsPayload,
  UpsertMissingInfoPayload,
} from './payload';
import { createModel } from '@rematch/core';
import { RootModel } from '.';
import {
  GetPaymentPlansResponse,
  GetPolicyResponse,
  ReponseFetchQuote,
} from './response';
import { quoteTransform } from 'src/lib/quoteTransform';

const defaultState: Partial<Quote> = {
  FirstName: '',
  LastName: '',
  Property: undefined,
  State: 'FL',
};

const _quote = createModel<RootModel>()({
  state: defaultState,
  reducers: {
    'resetQuote': (_: unknown, updatedQuote: Partial<Quote>) =>
      merge({}, defaultState, updatedQuote),
    'setQuote': (state: Partial<Quote>, updatedQuote: Partial<Quote>) =>
      merge({}, state, updatedQuote),
    'global/reset': (_: Partial<Quote>) => defaultState,
  },
  effects: (dispatch) => ({
    resume: async ({ ID }: ResumePayload) => {
      const { data } = await api.secure.get<ReponseFetchQuote>(`/quote/${ID}`);

      const dataTransf = quoteTransform(data);

      if (
        data.ResumeFrom === PAGES.OccupancyPage &&
        data.FormType === PRODUCTS.FLORIDA_HO3.FormType
      ) {
        await dispatch.session.stageChanges(dataTransf);
      }

      if (dataTransf?.WindMitigation?.WindMitigationDiscount) {
        await dispatch.session.skip({
          [PAGES.FormSelectionPage]: true,
          [PAGES.UniformQuestionnairePage]: true,
          [PAGES.UniformUploadPage]: true,
          [PAGES.BuildingQuestionnairePage]: true,
          [PAGES.BuildingUploadPage]: true,
          [PAGES.ResetWindMitPage]: false,
        });
      }

      dispatch.quote.setQuote(dataTransf);
    },

    newQuote: async (initialQuote: NewQuotePayload) => {
      const { data } = await api.secure.post('/quote', initialQuote);

      const { ID, QuoteNumber, Premium, MissingInfo } = data;

      const quote = {
        ID,
        Premium,
        RequiredMissingInfo: MissingInfo,
        QuoteNumber,
        ...initialQuote,
      };

      await dispatch.quote.setQuote(quote);

      return quote;
    },
    fetchQuote: async (_?: unknown, root?): Promise<Partial<Quote>> => {
      const state = root!;
      const { ID } = state.quote;
      const { data } = await api.secure.get<ReponseFetchQuote>(`/quote/${ID}`);

      const quote = quoteTransform(data);
      await dispatch.quote.resetQuote(quote);

      return quote;
    },
    upsertMissingInfo: async (
      {
        MissingInfo,
        Interview,
        Session,
        MissingInfoToDefault,
      }: UpsertMissingInfoPayload,
      root
    ) => {
      const { ID } = root.quote;

      const interviewMissingInfo = {
        OccupancyType: Interview.OccupancyType,
        PlumbingType: Interview.PlumbingType,
        HighRiseConstruction: Interview.HighRiseConstruction,
        FloorNumber: Interview.FloorNumber,
        FloorsAboveUnit: Interview.FloorsAboveUnit,
        NumberOfStories: Interview.NumberOfStories,
        HotWaterHeater: Interview.HotWaterHeater,
        UnitsAboveOrBelow: Interview.UnitsAboveOrBelow,
      };

      await api.secure.patch(`/quote/${ID}/missing-info`, {
        ID,
        AssociationId: root.user.email,
        MissingInfo,
        Interview: cleanEmpty(interviewMissingInfo),
        MissingInfoToDefault,
        FormType: root.quote.FormType,
        ...Session,
      });

      await dispatch.quote.fetchQuote();
    },

    setPackage: async (payload: SetPackagePayload, root) => {
      const { ID } = root.quote;
      const { data } = await api.secure.put(`/quote/${ID}/package`, {
        ID,
        ...payload,
      });
      const { Premium } = data;
      await dispatch.quote.setQuote({
        Premium,
      });
    },

    updateQuote: async (updates: UpdateQuotePayload, root) => {
      const { ID } = root.quote;

      const { data: currentQuote } = await api.secure.patch(`/quote/${ID}`, {
        ...updates,
        FormType: root.quote.FormType,
      });

      dispatch.quote.setQuote({ ...currentQuote });
    },
    deleteQuoteObjects: async (
      objectsToRemove: DeleteQuoteObjectsPayload,
      root
    ) => {
      const { ID } = root.quote;

      await api.secure.delete(`/quote/${ID}/objects`, objectsToRemove);
    },

    updateCoveragesAndDeductibles: async (
      { updates, session }: UpdateCoveragesAndDeductiblesPayload,
      root
    ) => {
      const { ID } = root.quote;
      const { Coverages, Deductibles, OtherStructures } = updates;

      const request = {
        Coverages: coveragesTransform.output(Coverages, root.quote.FormType),
        Deductibles,
        OtherStructures,
        ...session,
        FormType: root.quote.FormType,
      };

      const { data: response } = await api.secure.put(
        `/quote/${ID}/coverages`,
        request
      );

      dispatch.quote.setQuote({ Premium: response.Premium });
    },

    updateOptionalCoverage: async (
      { updates, session }: UpdateOptionalCoveragePayload,
      root
    ) => {
      const { ID } = root.quote;
      const { OptionalCoverages } = updates;

      const request = {
        Coverages: { ...OptionalCoverages },
        FormType: root.quote.FormType,
        ...session,
      };

      const paths = [
        'Coverages.PersonalPropertyIncreasedSpecialLimits.JewerlyWatchesFurs',
        'Coverages.PersonalPropertyIncreasedSpecialLimits.SilverwareGoldwarePewterware',
      ];

      if (
        request?.Coverages?.PermittedIncidentalOccupancyResidencePremises
          ?.BusinessLimit &&
        request?.Coverages?.PermittedIncidentalOccupancyResidencePremises
          ?.BusinessLimit > 0
      ) {
        paths.push(
          'Coverages.PermittedIncidentalOccupancyResidencePremises.BusinessLimit'
        );
      }

      paths.forEach((path) => convertPropertyToNumber(request, path));

      const { data: response } = await api.secure.put(
        `/quote/${ID}/optional-coverages`,
        request
      );

      dispatch.quote.setQuote({ Premium: response.Premium });
    },
    applyWindMit: async (formData, root) => {
      const { ID } = root.quote;

      const WindMitigationDiscount =
        formData?.data?.WindMitigation?.WindMitigationDiscount || false;

      const Questionnaire = formData.data.Questionnaire
        ? windMitTransform.input(
            JSON.parse(JSON.stringify(formData.data.Questionnaire))
          )
        : {};
      const windMitRequest = merge(
        {
          WindMitigationDiscount,
          ...formData.Session,
        },
        WindMitigationDiscount
          ? {
              [formData.data.SelectedFormType]: {
                WindMitInspectionDate: formData.data.WindMitInspectionDate,
                WindMitFileName: formData.data.WindMitFileName,
                ...Questionnaire,
              },
            }
          : {}
      );

      const { data: windMitQuestionnaireResponse } = await api.secure.put(
        `/quote/${ID}/wind-mitigation`,
        windMitRequest
      );
      dispatch.quote.setQuote({
        windMitQuestionnaireResponse,
      });
    },

    applyDiscount: async (formData: ApplyDiscountPayload, root) => {
      const { ID } = root.quote;

      const Discounts = formData.data.Discounts;

      const discountRequest = {
        ...Discounts,
        ...formData.Session,
      };
      const { data: discountsResponse } = await api.secure.put(
        `/quote/${ID}/discounts`,
        discountRequest
      );
      dispatch.quote.setQuote(discountsResponse);
    },
    uploadAttachment: async ({ attachment }: UploadAttachmentPayload, root) => {
      const {
        quote: { ID },
      } = root;
      const body = new FormData();
      body.append('file', attachment);
      body.append('fileName', attachment.name);

      const { data } = await api.secure.post(`/quote/${ID}/attachments`, body, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      return data.fileName;
    },
    uploadWindMitForm: async (data: UploadWindMitFormPayload) => {
      const WindMitFile = data.WindMitFile;
      let fileName = '';
      if (WindMitFile) {
        fileName = await dispatch.quote.uploadAttachment({
          attachment: WindMitFile,
        });
      }

      dispatch.quote.setQuote({
        WindMitigationFormData: {
          InspectionDate: data.InspectionDate,
          File: fileName?.length > 0 ? fileName : null,
        },
      });
      return fileName;
    },

    upsertAdditionalInterests: async (
      payload: UpsertAdditionalInterestsPayload,
      root
    ) => {
      const { ID } = root.quote;
      const AdditionalInterests = [
        payload.FirstMortgagee,
        payload.SecondMortgagee,
        payload.Trust,
      ]
        .map((item) => {
          if (item && item.isAdditional) {
            const { isAdditional, ...rest } = item;
            return rest;
          }
          return undefined;
        })
        .filter((item) => item !== undefined);

      const request = {
        ApplicantMailingAddress: payload.MailingAddress ?? undefined,
        CoApplicant: payload.CoApplicant ?? undefined,
        AdditionalInterests,
        FormType: root.quote.FormType,
        ...payload.Session,
      };

      await api.secure.put(`/quote/${ID}/additional-interests`, request);
    },

    fetchInitialValues: async (_?: unknown, root?) => {
      const state = root!;
      const { ID } = state.quote;
      const { data } = await api.secure.get<ReponseFetchQuote>(`/quote/${ID}`);

      const initialValues = quoteTransform(data);

      await dispatch.session.stageChanges(initialValues);

      return initialValues;
    },
    getPolicyNumber: async (_?: unknown, root?) => {
      const state = root!;
      const {
        quote: { ID },
      } = state;
      const { data } = await api.secure.get<GetPolicyResponse>(
        `/quote/${ID}/policy-number`
      );
      dispatch.quote.setQuote({
        PolicyNumber: data.PolicyNumber,
        PartyId: data.PartyId,
      });
      return { PolicyNumber: data.PolicyNumber, PartyId: data.PartyId };
    },

    getPolicyNumberWithPolicyId: async (
      payload: GetPolicyNumberWithPolicyIdPayload,
      root
    ) => {
      const {
        quote: { ID },
      } = root;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { data } = await api.secure.get<any>(
        `/quote/${ID}/policies/${payload.PolicyId}/policy-number`
      );
      dispatch.quote.setQuote({
        PolicyNumber: data.PolicyNumber,
      });
      return { PolicyNumber: data.PolicyNumber };
    },

    putPaymentPlan: async (payload: PutPaymentPlanPayload, root) => {
      const { ID } = root.quote;

      let request = {
        PayPlan: payload.PaymentPlan,
        PolicyBillType: payload.PolicyBillingType,
        ...payload.Session,
      };

      if (payload.PolicyBillingType === 'PF1') {
        request = { ...request, PayPlan: payload.RenewalPlanCode };
      }

      const { data } = await api.secure.put(`/quote/${ID}/payment-plan`, {
        ...request,
        FormType: root.quote.FormType,
      });

      dispatch.quote.setQuote({ ...root.quote, ...data });
    },
    getPaymentPlans: async (_?: unknown, root?) => {
      const state = root!;
      const {
        quote: { ID },
      } = state;
      const { data } = await api.secure.get<GetPaymentPlansResponse>(
        `/quote/${ID}/payment-plan`
      );

      dispatch.quote.setQuote({ PayPlans: data.PayPlans });
    },
    getPolicyAttachment: async function (
      { attachmentTitle }: GetPolicyAttachmentPayload,
      root
    ) {
      const {
        quote: { PolicyNumber },
      } = root;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const file = await api.secure.get<any>(
        `/policies/${PolicyNumber}/policy-attachment`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf',
          },
          responseType: 'blob',
        }
      );

      const blob = new Blob([file.data], { type: 'application/pdf' });

      // Old MS Edge don't allow using a blob object directly as link href
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const nav = window.navigator as any;
      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(blob);
      } else {
        const objUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = objUrl;
        link.download = `${attachmentTitle}.pdf`;
        link.click();

        // For Firefox it is necessary to delay revoking the ObjectURL.
        setTimeout(() => {
          window.URL.revokeObjectURL(objUrl);
        }, 250);
      }
    },
  }),
});

export default _quote;
