const useFeatureFlags = () => {
  const flags = {
    enableCampaignRoute:
      process.env.REACT_APP_FEATURE_FLAG_ENABLE_CAMPAIGN_ROUTE === 'true',
    maintenanceMode:
      process.env.REACT_APP_FEATURE_FLAG_MAINTENANCE_MODE === 'true',
    showMaintenanceTimeWarning:
      process.env.REACT_APP_FEATURE_FLAG_SHOW_MAINTENANCE_TIME_WARNING ===
      'true',
    useFlexMicroformV04: false, //deprecated, will be removed shortly.
    enableExplicitResumption:
      process.env.REACT_APP_FEATURE_FLAG_ENABLE_EXPLICIT_RESUMPTION === 'true',
    portalOneAuthenticationKey:
      process.env.REACT_APP_PORTAL_ONE_AUTHENTICATION_KEY,
    portalOneApiUrl: process.env.REACT_APP_PORTAL_ONE_API_URL + '/Api/Api',
    portalOneBaseUrl: process.env.REACT_APP_PORTAL_ONE_API_URL,
  };

  return flags;
};

export default useFeatureFlags;
