import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApi, useModel, useAuth0, useErrorHandling } from 'src/lib/hooks';
import { LOADING_MESSAGE, PAGES } from 'src/products/shared/constants';
import { LoadingContent, Verify } from 'src/common';
import LoginToContinue from './LoginToContinue';
import InvalidResumption from './InvalidResumption';
import ResumptionError from './ResumptionError';
import { ErrorTypes } from 'src/products/shared/constants';

const Resume = () => {
  const api = useApi();
  const history = useHistory();
  const { user } = useAuth0();
  const { signOut } = useModel.user.dispatch();
  const { resume } = useModel.quote.dispatch();
  const [error, setError] = useState('');
  const [isResuming, setIsResuming] = useState(true);
  const [invalidResumption, setInvalidResumption] = useState(false);
  const [exception, setException] = useState(null);

  const { handleError } = useErrorHandling();
  interface QuoteData {
    QuoteId: string;
    PolicyNumber?: string;
    Status: string;
    // Agrega más propiedades según sea necesario
  }

  const verifyReferral = (data: QuoteData) => {
    if (
      data.Status === ErrorTypes.REFERRED_TO_INT_AGENT ||
      data.Status === ErrorTypes.REFERRED_TO_EXT_AGENT
    ) {
      const error = new Error(data.Status);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (error as any).statusCode = 422;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (error as any).errorType = data.Status;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (error as any).message = data.Status;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (error as any).name = 'Error';
      throw error;
    }
  };

  const handleResumption = async () => {
    setIsResuming(true);
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { data } = await api.secure.get<any>('/profiles/quotes');

      const quoteToResume = data ? { ID: data.QuoteId, ...data } : null;
      if (quoteToResume) {
        verifyReferral(quoteToResume);
        if (!!quoteToResume.PolicyNumber) {
          quoteToResume.ResumeFrom = PAGES.CompletionPage;
        }
        await resume(quoteToResume);
        history.replace('/onboarding');
      } else {
        setInvalidResumption(true);
        setIsResuming(false);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setIsResuming(false);
      if (err.response?.status === 404) {
        setInvalidResumption(true);
        setError('');
      } else {
        handleError(err).then(setError).catch(setException);
      }
    }
  };

  useEffect(() => {
    if (user?.hasSession) {
      handleResumption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.hasSession]);

  if (exception) {
    throw exception;
  }

  if (invalidResumption) {
    return (
      <InvalidResumption
        {...(user?.hasSession && {
          email: user.email,
        })}
      />
    );
  }

  if (!user?.hasSession && !user?.loggingIn) {
    return <LoginToContinue />;
  } else if (user?.loggingIn) {
    return (
      <Verify onBack={signOut} layoutProps={{ displayNavElements: false }} />
    );
  }

  if (isResuming) {
    return <LoadingContent text={LOADING_MESSAGE.RESUMPTION} />;
  }

  return <ResumptionError error={error} retry={handleResumption} />;
};

export default Resume;
