import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { validateBirthDate, validateEmail } from 'src/lib/validations';
import suffixes from 'src/lib/suffixes';

import {
  BasicLayout,
  Card,
  Error,
  Footer,
  Form,
  RadioInput,
  Input,
  AutocompleteAddress,
  Select,
  MaskedInput,
  DateInput,
  Icon,
} from 'src/common';
import { useAuth0, useModel, useForm, useFeatureFlags } from 'src/lib/hooks';
import products from 'src/products';
import { links } from 'src/lib/enums';
import { PRODUCTS } from 'src/products/products';
import * as reactRedux from 'react-redux';
import config from 'src/config';
import { PAGES } from 'src/products/shared/constants';

type LandingFormData = {
  FirstName: string;
  LastName: string;
  FormType: string | undefined;
  Suffix?: string;
  Email: string;
  DateOfBirth: string;
  Phone: string;
  Property: {
    Location: {
      Address1: string;
      Address2: string;
      City: string;
      State: string;
      ZipCode: string;
    };
  };
};

const Landing = () => {
  const history = useHistory<{ isHistorical: boolean }>();
  const { pendingChanges: defaultValues } = useModel.session();
  const { setPendingChanges } = useModel.session.dispatch();
  const form = useForm({
    defaultValues: history.location?.state?.isHistorical ? defaultValues : {},
    onError: async ({ defaultOnError }) => {
      await defaultOnError();
    },
  });
  const {
    control,
    handleSubmit,
    errors,
    formError,
    formState: { isSubmitting },
    register,
  } = form;
  const dispatch = reactRedux.useDispatch();
  const { setQuote } = useModel.quote.dispatch();
  const { logout } = useAuth0();
  const { enableExplicitResumption, enableCampaignRoute } = useFeatureFlags();
  const { Property } = useModel.quote();

  const reset = () => {
    dispatch({ type: 'global/reset' });
  };

  useEffect(() => {
    reset();
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = handleSubmit(async (data: LandingFormData) => {
    if (
      data.Property.Location.State !== 'FL' &&
      data.Property.Location.State !== 'SC'
    ) {
      history.push('/unsupported');
      return;
    }
    data.FirstName = data.FirstName.trim();
    data.LastName = data.LastName.trim();
    if (!config.productSelector) {
      data.FormType = PRODUCTS.FLORIDA_HO6.FormType;
    }

    const selectedProduct = Object.values(PRODUCTS).find(
      (product) => product.FormType === data.FormType
    );
    if (!selectedProduct) return;

    const { FullState, FormTypeFolder } = selectedProduct;
    products?.[FullState.toLowerCase()]?.[FormTypeFolder]?.preload?.();

    const initialQuote = {
      State: selectedProduct.State,
      FormType: selectedProduct.FormType,
      WritingCompany: 'HomeFront Insurance',
      FirstName: data.FirstName,
      LastName: data.LastName,
      Suffix: data.Suffix,
      Email: data.Email.toLowerCase(),
      DateOfBirth: data.DateOfBirth,
      Phone: data.Phone,
      Property: data.Property,
      ResumeFrom: PAGES.OccupancyPage,
      SkipList: [],
      FurthestReached: PAGES.OccupancyPage,
    };

    if (initialQuote.Suffix === '') {
      delete initialQuote.Suffix;
    }

    setPendingChanges(initialQuote);
    setQuote(initialQuote);
    history.push('/onboarding');
  });

  const suffixOptions = suffixes.map((s) => ({
    id: s.value,
    title: s.name,
  }));

  return (
    <BasicLayout displayNavElements={false}>
      <Card title="Welcome!">
        <p className="text-center md:px-11 pb-6">
          We think buying insurance should be a breeze. Tell us a little about
          yourself and get your estimated premium instantly.
        </p>
        {formError && <Error>{formError}</Error>}
        <Form
          id="address-select-form"
          onSubmit={onSubmit}
          className="space-y-4"
        >
          <div className="flex flex-col items-center text-center mb-5 mx-auto">
            {config.productSelector ? (
              <div>
                <RadioInput
                  label={
                    <strong className="text-violet">
                      What type of property are you looking to insure?
                    </strong>
                  }
                  name="FormType"
                  containerClassName="flex flex-col items-center text-center font-bold mb-5 max-w-md mx-auto"
                  radioInputClassName="space-x-0"
                  control={control}
                  rules={{ required: 'Required' }}
                  error={control.getFieldError('FormType')}
                >
                  <RadioInput.Option
                    label="Single-Family Home"
                    value={PRODUCTS.FLORIDA_HO3.FormType}
                    icon="singleFamily"
                    iconSize="3xl"
                  />
                  <RadioInput.Option
                    label="Condo"
                    value={PRODUCTS.FLORIDA_HO6.FormType}
                    icon="condo"
                    iconSize="3xl"
                    className="xs:pr-4 pr-0"
                  />
                </RadioInput>
              </div>
            ) : (
              <>
                <Icon name="condo" size={'3xl'} className="pr-0 mb-4" />
                <p className="text-violet text-2xl font-bold mt-0.5">
                  {' '}
                  Let’s start with the basics.
                </p>
              </>
            )}
          </div>
          <AutocompleteAddress
            containerClassName="flex-1"
            label="Property"
            name="Property.Location"
            international={false}
            column={false}
            isHistorical={history.location?.state?.isHistorical}
            {...form}
            control={control}
            errors={errors}
          />
          <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0 mt-2">
            <Input
              containerClassName="flex-1"
              label="First Name"
              error={control.getFieldError('FirstName')}
              {...register('FirstName', {
                required: 'First name is required.',
                validate: (value) =>
                  value.trim().length > 0 || 'First name is required.',
              })}
              name="FirstName"
              autoComplete="given-name"
            />
            <Input
              containerClassName="flex-1"
              label="Last Name"
              error={control.getFieldError('LastName')}
              {...register('LastName', {
                required: 'Last name is required.',
                validate: (value) =>
                  value.trim().length > 0 || 'Last name is required.',
              })}
              name="LastName"
              autoComplete="family-name"
            />
            <div className="w-24">
              <Select
                label="Suffix"
                name="Suffix"
                placeholder="Suffix"
                defaultValue=""
                error={control.getFieldError('Suffix')}
                options={suffixOptions}
                control={control}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0 mt-2">
            <DateInput
              containerClassName="flex-1"
              label="Date of Birth"
              name="DateOfBirth"
              error={control.getFieldError('DateOfBirth')}
              control={control}
              rules={{
                required: 'Date of Birth is required',
                validate: (value) => validateBirthDate(value),
              }}
              autoComplete="bday"
            />
            <MaskedInput
              containerClassName="flex-1"
              label="Phone Number"
              name="Phone"
              error={control.getFieldError('Phone')}
              mask="(999) 999-9999"
              placeholder="(000) 000-0000"
              rules={{
                required: { value: true, message: 'Phone number is required.' },
                pattern: {
                  value: /\(\d{3}\) \d{3}-\d{4}/,
                  message: 'Invalid phone number.',
                },
              }}
              control={control}
              autoComplete="tel-national"
            />
          </div>
          <Input
            containerClassName="flex flex-col flex-grow"
            label="Email"
            error={control.getFieldError('Email')}
            placeholder="name@domain.com"
            {...register('Email', {
              required: { value: true, message: 'Email is required.' },
              validate: validateEmail,
            })}
            name="Email"
            autoComplete="email"
          />
        </Form>
        {(Property?.Location || enableExplicitResumption) && (
          <div className="flex flex-row justify-center mt-4">
            <span className="text-sm">Already started a quote?</span>
            <Link to="/resume" className="underline text-violet text-sm pl-1">
              Get back to it!
            </Link>
          </div>
        )}
        {enableCampaignRoute && (
          <div className="flex flex-col items-center py-3">
            <Link to="/campaign" className="mt-1 underline text-center text-sm">
              Received an invitation code? Enter here
            </Link>
          </div>
        )}
        <div className="flex max-w mx-auto mt-6 text-center text-2xs font-normal text-input-light ">
          <span>
            By providing your email and selecting “Get Started”, you agree to
            our{' '}
            <a
              href={links.privacyPolicy}
              target="_blank"
              rel="noreferrer"
              className="text-violet underline"
            >
              Privacy Policy
            </a>{' '}
            and to provide accurate and complete information about your
            property. You also consent to receiving contact from an appointed
            Slide Agent, electronic communications, and using an e-signature
            process. See our{' '}
            <a
              href={links.termsOfUse}
              target="_blank"
              rel="noreferrer"
              className="text-violet underline"
            >
              Terms of Use
            </a>{' '}
            for more information.
          </span>
        </div>
      </Card>
      <Footer displayAssitance={false}>
        <Footer.Next
          onClick={onSubmit}
          rounded
          hideIcon={true}
          label="Get Started"
          loading={isSubmitting}
        />
      </Footer>
    </BasicLayout>
  );
};

export default Landing;
