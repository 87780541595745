import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { getPersistor } from '@rematch/persist';

import { LoadingContent, Viewport, ErrorBoundary } from 'src/common';
import Refocus from 'src/lib/Refocus';
import store from 'src/lib/store';
import App from './App';
import './index.css';
import config from './config';

const container = document.getElementById('root');
const root = createRoot(container);

if (config.a11y === 'true') {
  import('@axe-core/react').then(({ default: axe }) => {
    setInterval(() => axe(React, root, 1000), 1000);
  });
}

if (config.tracking.googleAnalytics === 'true') {
  //<!-- Asynchronous loading of the Google Analytics script -->
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-3LV7LKDTJE';
  document.getElementById('google-analytics-script')?.appendChild(script);

  //<!-- Google Analytics initialization -->
  const inlineScript = document.createElement('script');
  inlineScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-3LV7LKDTJE');
  `;
  document.getElementById('google-analytics-script')?.appendChild(inlineScript);
}

root.render(
  <ReduxProvider store={store}>
    <Router>
      <Refocus>
        <Viewport>
          <ErrorBoundary>
            <PersistGate
              loading={<LoadingContent />}
              persistor={getPersistor()}
            >
              <App />
            </PersistGate>
          </ErrorBoundary>
        </Viewport>
      </Refocus>
    </Router>
  </ReduxProvider>
);
