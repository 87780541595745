import { useHistory, useLocation } from 'react-router-dom';
import {
  useAuth0,
  useForm,
  useModel,
  useResponsiveVariant,
} from 'src/lib/hooks';

import withSession from 'src/lib/hoc/withSession';
import {
  BasicLayout,
  Footer,
  Form,
  Card,
  Label,
  Input,
  Error,
  Icon,
} from 'src/common';
import { validateEmail } from 'src/lib/validations';

type LoginToContinueFormData = {
  Email: string;
};

const LoginToContinue = () => {
  const history = useHistory();
  const location = useLocation<{ expired: boolean }>();
  const { startLogin, handleStartLoginError } = useAuth0();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    control,
    formError,
  } = useForm({
    onError: async ({ err, setFormError, defaultOnError }) => {
      if (err.statusCode) {
        setFormError(handleStartLoginError(err));
      } else {
        await defaultOnError();
      }
    },
  });

  const defaultLabel = useResponsiveVariant({
    default: { next: 'Yes', back: 'No' },
    xs: { next: `Let's Go`, back: 'No Thanks' },
  });

  const quote = useModel.quote();

  const { Address1, Address2, City, State, ZipCode } =
    quote?.Property?.Location || {};
  const address1 = `${Address1}${Address2 ? ' ' + Address2 : ''}`;
  const address2 = `${City}, ${State} ${ZipCode}`;

  const { expired } = location?.state || {};

  const handleLogin = handleSubmit(async (data: LoginToContinueFormData) => {
    await startLogin(data.Email);
  });

  const handleGoBack = () => {
    history.push('/');
  };

  return (
    <BasicLayout displayNavElements={false}>
      <Card
        icon={expired ? 'binocularsCircle' : 'welcome'}
        title={expired ? `Where'd you go?` : 'Welcome back!'}
      >
        <div className="flex flex-col items-center mb-6">
          {quote?.Property?.Location && (
            <>
              {expired ? (
                <p className="text-center text-input">
                  Your session timed out. Want to resume your quote in progress?
                </p>
              ) : (
                <p className="text-center text-input">
                  We see you have a quote in progress for
                </p>
              )}
              <div className="flex flex-col items-center m-4">
                <Icon name="mapPin" className="mb-2" size="lg" />
                <Label className="font-bold text-center text-violet">
                  {address1}
                  <br />
                  {address2}
                </Label>
              </div>
            </>
          )}
          <Label className="font-bold text-input">
            Would you like to continue?
          </Label>
          <p className="text-center text-input">
            We'll email you a code, and you can pick up right where you left
            off.
          </p>
        </div>

        {formError && <Error>{formError}</Error>}
        <Form onSubmit={handleLogin}>
          <Input
            type="email"
            label="Email"
            error={control.getFieldError('Email')}
            placeholder="name@domain.com"
            {...register('Email', {
              required: {
                value: true,
                message: 'Email is required.',
              },
              validate: validateEmail,
            })}
            name="Email"
            containerClassName="max-w-xs mx-auto"
          />
        </Form>
      </Card>

      <Footer>
        <Footer.Next
          label={defaultLabel.next}
          onClick={handleLogin}
          loading={isSubmitting}
        />
        <Footer.Back
          label={defaultLabel.back}
          onClick={handleGoBack}
          disabled={isSubmitting}
        />
      </Footer>
    </BasicLayout>
  );
};

export default withSession(LoginToContinue, {
  secured: false,
});
