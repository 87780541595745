import { jwtDecode } from 'jwt-decode';
import { User } from './model';
import { createModel } from '@rematch/core';
import { RootModel } from '.';

const _user = createModel<RootModel>()({
  state: JSON.parse(sessionStorage.getItem('user') || '{}'),
  reducers: {
    set: (state: User | null, user: User | null) =>
      user ? { ...state, ...user } : null,
  },
  effects: (dispatch) => ({
    save: (update: User, root) => {
      if (update) {
        const { token, ...user } = update;

        user.id = token ? jwtDecode(token).sub : undefined;
        user.hasSession = !!token || !!user.hasSession;
        user.loggingIn = token ? false : !!user.loggingIn;
        user.refreshing = token ? false : !!user.refreshing;
        user.firstName = root.quote?.FirstName || '';
        user.lastName = root.quote?.LastName || '';

        const userState = {
          ...root.user,
          ...user,
          token,
        };
        dispatch.user.set(userState);

        sessionStorage.setItem(
          'user',
          JSON.stringify({ ...root.user, ...user })
        );
        return userState;
      } else {
        dispatch.user.set(null);
        sessionStorage.removeItem('user');
        return null;
      }
    },
    signOut: (reset = true) => {
      dispatch.user.set(null);
      if (reset) {
        dispatch({ type: 'global/reset' });
      }
    },
  }),
});

export default _user;
