import dayjs from 'dayjs';
import {
  Coverages,
  Deductibles,
  DetachedScreenEnclosure,
  DwellingInfo,
  Interview,
  MissingInfo,
  OptionalCoverages,
  OtherStructures,
  PremiumNames,
  Premiums,
  Quote,
  WindMitigation,
} from 'src/models/model';
import { QuoteHO3, QuoteResponse } from 'src/models/response';
import { currencyTransform } from './transforms';

export const quoteTransform = (quote: QuoteResponse): Partial<Quote> => {
  const RequiredMissingInfo = buildRequiredMissingInfo(quote);
  const MissingInfo = buildMissingInfo(quote);
  const Coverages = buildCoverages(quote);
  const Deductibles = buildDeductibles(quote);
  const Interview = buildInterview(quote);

  const PreviousCarrier = quote.Interview?.PreviousCarrier
    ? {
        PreviousCarrier: quote.Interview.PreviousCarrier,
        PriorExpirationDate: quote.Interview.PriorExpirationDate || undefined,
      }
    : undefined;

  const OptionalCoverages = buildOptionalCoverages(quote);

  const _quote: Partial<Quote> = {
    ID: quote.ID,
    QuoteNumber: quote.QuoteNumber,
    PolicyId: quote.PolicyId,
    PolicyNumber: quote.PolicyNumber,
    Property: { Location: quote.PropertyAddress },
    MailingAddress: quote.MailingAddress,
    Customer: quote.Customer,
    TotalFeesAndAssessment: quote.TotalFeesAndAssessment,
    TotalOptionalCoverages: quote.TotalOptionalCoverages,
    TotalSPPLimit: quote.TotalSPPLimit,
    ReplacementCost: quote.ReplacementCost,
    Premium: Number(quote.Premium),
    SelectedPackage: quote.SelectedPackage,
    QualityGrades: quote.QualityGrades,
    MissingInfo: MissingInfo,
    Coverages: Coverages,
    Deductibles: Deductibles,
    PreviousCarrier: PreviousCarrier,
    OptionalCoverages: OptionalCoverages,
    Interview: Interview,
    Discounts: quote.Discounts,
    Underwriting: {
      ...quote.Underwriting,
      FirstPartyLimit: Number(quote.Underwriting?.FirstPartyLimit) ?? 0,
      ThirdPartyLimit: Number(quote.Underwriting?.ThirdPartyLimit) ?? 0,
    },
    Packages: quote.Packages,
    WindMitigation: quote.WindMitigation,
    SkipList: quote.SkipList,
    FurthestReached: quote.FurthestReached,
    ResumeFrom: quote.ResumeFrom,
    FirstName: quote.Customer.FirstName,
    LastName: quote.Customer.LastName,
    Email: quote.Customer.Email,
    State: quote.PropertyAddress?.State ?? 'FL',
    Phase: '',
    PartyId: '',
    FormType: quote.FormType,
    RequiredMissingInfo: RequiredMissingInfo,
  };

  if (quote.FirstMortgagee)
    _quote.FirstMortgagee = {
      Type: 'FirstMortgagee',
      Name: quote.FirstMortgagee?.Name,
      PrimaryPhone: quote.FirstMortgagee?.PhoneNumber,
      LoanNumber: quote.FirstMortgagee?.LoanNumber,
      Country: quote.FirstMortgagee?.Country,
      Address: quote.FirstMortgagee?.Address,
    };
  if (quote.SecondMortgagee) {
    _quote.SecondMortgagee = {
      Type: 'SecondMortgagee',
      Name: quote.SecondMortgagee.Name || '',
      PrimaryPhone: quote.SecondMortgagee.PhoneNumber || '',
      LoanNumber: quote.SecondMortgagee.LoanNumber || '',
      Country: quote.SecondMortgagee.Country,
      Address: quote.SecondMortgagee.Address,
    };
  }

  if (quote.Trust) {
    _quote.Trust = {
      Type: 'Trust',
      Name: quote.Trust.Name || '',
      PrimaryPhone: quote.Trust.PhoneNumber || '',
      LoanNumber: quote.Trust.LoanNumber || '',
      Country: quote.Trust.Country,
      Address: quote.Trust.Address,
    };
  }

  if (quote.CoApplicant) {
    _quote.CoApplicant = {
      AddCoApplicant: !!quote.CoApplicant,
      DateOfBirth: quote.CoApplicant.DateOfBirth,
      EmailAddress: quote.CoApplicant.Email,
      FirstName: quote.CoApplicant.FirstName,
      LastName: quote.CoApplicant.LastName,
      PrimaryPhone: quote.CoApplicant.Phone,
    };
  }

  _quote.MissingInfo = MissingInfo;
  _quote.Coverages = Coverages;
  _quote.Deductibles = Deductibles;
  _quote.PreviousCarrier = PreviousCarrier;
  _quote.OptionalCoverages = OptionalCoverages;
  _quote.Interview = Interview;
  if (quote.FormType === 'HO-3') {
    const OtherStructures = buildOtherStructures(quote);
    const DwellingInfo = buildDwellingInfo(quote);
    _quote.OtherStructures = OtherStructures;
    _quote.DwellingInfo = DwellingInfo;
  }

  return _quote;
};

const buildInterview = (quote: QuoteResponse): Interview => {
  return {
    EffectiveDate: quote.Interview?.EffectiveDate ?? '',
    FloorsAboveUnit: quote.Interview?.FloorsAboveUnit ?? '',
    OccupancyType: quote.Interview?.OccupancyType ?? '',
    UnitsAboveOrBelow: quote.Interview?.UnitsAboveOrBelow ?? false,
    SuperiorConstruction: quote.Interview?.SuperiorConstruction ?? false,
  };
};

const buildRequiredMissingInfo = (quote: QuoteResponse): string[] => {
  const { MissingInfo } = quote;
  const RequiredMissingInfo: string[] = [];

  if (!MissingInfo) {
    return RequiredMissingInfo;
  }

  if (!(parseInt(MissingInfo?.YearBuilt) > 0)) {
    RequiredMissingInfo.push('YearBuilt');
  }
  if (!(parseInt(MissingInfo?.RoofYear) > 0)) {
    RequiredMissingInfo.push('RoofYear');
  }
  if (!(parseInt(MissingInfo?.SquareFeet) > 0)) {
    RequiredMissingInfo.push('SquareFeet');
  }
  if (!MissingInfo?.RoofMaterial) {
    RequiredMissingInfo.push('RoofMaterial');
  }
  if (!MissingInfo?.RoofGeometry) {
    RequiredMissingInfo.push('RoofShape');
  }
  if (!MissingInfo?.Construction) {
    RequiredMissingInfo.push('Construction');
  }
  if (!MissingInfo?.DwellingType) {
    RequiredMissingInfo.push('DwellingType');
  }
  if (
    !(parseInt(MissingInfo?.YearBuilt) > 0) ||
    parseInt(MissingInfo?.YearBuilt) <= dayjs().add(-16, 'year').year()
  ) {
    RequiredMissingInfo.push('HotWaterHeater');
  }
  return RequiredMissingInfo;
};

const buildMissingInfo = (quote: QuoteResponse): MissingInfo | undefined => {
  if (!quote.MissingInfo) {
    return;
  }

  const { MissingInfo } = quote;

  const YearBuilt =
    MissingInfo.YearBuilt !== '0' ? MissingInfo.YearBuilt : undefined;

  const RoofYear =
    MissingInfo.RoofYear !== '0' ? MissingInfo.RoofYear : undefined;
  const SquareFeet =
    MissingInfo.SquareFeet !== '0' ? MissingInfo.SquareFeet : undefined;

  const {
    Construction,
    DwellingType,
    ExteriorWallFinish,
    FoundationType,
    RoofGeometry,
    RoofMaterial,
  } = MissingInfo;

  return {
    YearBuilt,
    RoofYear,
    SquareFeet,
    Construction,
    DwellingType,
    ExteriorWallFinish,
    FoundationType,
    RoofGeometry,
    RoofMaterial,
  };
};

const buildCoverages = (quote: QuoteResponse): Coverages | undefined => {
  if (!quote.Coverages) {
    return;
  }
  const CoverageA = quote.Coverages.CoverageA?.Value ?? 0;
  const CoverageB = quote.Coverages.CoverageB?.Value ?? 0;
  const CoverageC = quote.Coverages.CoverageC?.Value ?? 0;
  const CoverageD = quote.Coverages.CoverageD?.Value ?? 0;
  const CoverageE = quote.Coverages.CoverageE;
  const CoverageF = quote.Coverages.CoverageF;

  return { CoverageA, CoverageB, CoverageC, CoverageD, CoverageE, CoverageF };
};

const HurricaneDeductibleMap: Record<string, string> = {
  '0': 'Excluded',
  '500': '$500',
  '1000': '$1,000',
  '1': '1%',
  '2': '2%',
  '5': '5%',
  '10': '10%',
};

const OtherPerilsMap: Record<string, string> = {
  '500': '$500',
  '1000': '$1,000',
  '2500': '$2,500',
  '5000': '$5,000',
  '7500': '$7,500',
  '10000': '$10,000',
};

const buildDeductibles = (quote: QuoteResponse): Deductibles | undefined => {
  if (!quote.Deductibles) {
    return;
  }

  const HurricaneDeductible =
    HurricaneDeductibleMap[quote.Deductibles.HurricaneDeductible] ??
    'Invalid amount';

  const OtherPerils: string =
    OtherPerilsMap[currencyTransform.output(quote.Deductibles.OtherPerils)] ??
    'Invalid amount';

  return { HurricaneDeductible, OtherPerils };
};

const buildOtherStructures = (quote: QuoteHO3): OtherStructures => {
  const hasOnPremises =
    quote.OtherStructures?.OnPremises &&
    quote.OtherStructures?.OnPremises?.length > 0;

  const OnPremises = hasOnPremises
    ? quote.OtherStructures?.OnPremises.map((item) => {
        const Id = item.Id;
        const Limit =
          Number(item.Limit) === 0
            ? 0
            : Math.ceil(
                (Number(item.Limit) / quote.Coverages.CoverageA.Value) * 100
              );

        const OtherDescription = item?.Description?.includes('Other -')
          ? item.Description.replace('Other - ', '')
          : undefined;
        const Description = item?.Description?.includes('Other -')
          ? 'Other'
          : item.Description;
        return { Id, Limit, OtherDescription, Description };
      })
    : [];

  const hasRentedToOthers =
    quote.OtherStructures?.RentedToOthers &&
    quote.OtherStructures?.RentedToOthers?.length > 0;

  const RentedToOthers = hasRentedToOthers
    ? quote.OtherStructures?.RentedToOthers.map((item) => {
        const Id = item.Id;
        const Limit =
          Number(item.Limit) === 0
            ? 0
            : Math.ceil(
                (Number(item.Limit) / quote.Coverages.CoverageA.Value) * 100
              );

        const OtherDescription = item?.Description?.includes('Other -')
          ? item.Description.replace('Other - ', '')
          : undefined;
        const Description = item?.Description?.includes('Other -')
          ? 'Other'
          : item.Description;
        return { Id, Limit, OtherDescription, Description };
      })
    : [];

  return { OnPremises, RentedToOthers, hasOnPremises, hasRentedToOthers };
};

export const buildWindMitigation = (
  quote: QuoteResponse
): WindMitigation | undefined => {
  if (quote.WindMitigation?.RoofCover) {
    return;
  }
  const { WindMitigation } = quote;
  return {
    RoofCover: windMitFormMapping.RoofCover[WindMitigation.RoofCover],
    RoofDeckAttachment:
      windMitFormMapping.RoofDeckAttachment[WindMitigation.RoofDeckAttachment],
    RoofToWallConnection:
      windMitFormMapping.RoofToWallConnection[
        WindMitigation.RoofToWallConnection
      ],
    RoofGeometry: windMitFormMapping.RoofGeometry[WindMitigation.RoofGeometry],
    SecondaryWaterResistance:
      windMitFormMapping.SecondaryWaterResistance[
        WindMitigation.SecondaryWaterResistance
      ],
    OpeningProtection:
      windMitFormMapping.OpeningProtection[WindMitigation.OpeningProtection],
  };
};

const buildOptionalCoverages = (
  quote: QuoteResponse
): OptionalCoverages | undefined => {
  const Premiums: Premiums = {};
  if (!quote.OptionalCoverages) {
    return;
  }

  (
    Object.keys(quote.OptionalCoverages ?? {}) as Array<
      keyof typeof quote.OptionalCoverages
    >
  ).forEach((key) => {
    const optCoverage = quote.OptionalCoverages[key];
    if (
      typeof optCoverage === 'object' &&
      'Premium' in optCoverage &&
      optCoverage.Premium !== undefined &&
      optCoverage.Premium > 0
    ) {
      Premiums[key as PremiumNames] = optCoverage.Limit ?? optCoverage.Premium;
    }
  });

  const optionalCoverages: OptionalCoverages = {
    WaterBackupAndSumpOverflow:
      quote.OptionalCoverages?.WaterBackupAndSumpOverflow.Value,
    WaterDamageExclusion: quote.OptionalCoverages?.WaterDamageExclusion.Value,
    LimitedWaterDamage: quote.OptionalCoverages?.LimitedWaterDamage.Value,
    EquipmentBreakdown: quote.OptionalCoverages?.EquipmentBreakdown.Value,
    ServiceLine: quote.OptionalCoverages?.ServiceLine.Value,
    AnimalLiablility: quote.OptionalCoverages?.AnimalLiablility.Value,
    IdentityFraudExpenseCoverage:
      quote.OptionalCoverages?.IdentityFraudExpenseCoverage.Value,
    PersonalInjury: quote.OptionalCoverages?.PersonalInjury.Value,
    PersonalPropertyReplacementCost:
      quote.OptionalCoverages?.PersonalPropertyReplacementCost.Value,
    UnitOwnersSpecialCoverage:
      quote.OptionalCoverages?.UnitOwnersSpecialCoverage.Value,
    UnitOwnersCoverageA: quote.OptionalCoverages?.UnitOwnersCoverageA.Value,
    GolfCartPhysical: quote.OptionalCoverages?.GolfCartPhysical.Value,
    ScreenEnclosureHurricane:
      quote.OptionalCoverages?.ScreenEnclosureHurricane.Value,
    OrdinanceOrLaw: quote.OptionalCoverages?.OrdinanceOrLaw.Value,
    LossAssessment: quote.OptionalCoverages?.LossAssessment.Value,
    LimitedFungiBacteria: quote.OptionalCoverages?.LimitedFungiBacteria.Value,
    PermittedIncidentalOccupancyResidencePremises:
      quote.OptionalCoverages?.PermittedIncidentalOccupancyResidencePremises,
    PersonalPropertyIncreasedSpecialLimits:
      quote.OptionalCoverages?.PersonalPropertyIncreasedSpecialLimits,
    ScheduledPersonalProperty:
      quote.OptionalCoverages?.ScheduledPersonalProperty.map((spp) => ({
        ...spp,
        SPPFileName: null,
        SPPFileStream:
          spp.SPPFileName && spp.SPPFileName.length > 0
            ? new File([], `${spp.SPPFileName}.pdf`, {
                type: 'applicaiton/pdf',
              })
            : null,
      })),
    Premiums: Premiums,
  };

  return optionalCoverages;
};
const buildDwellingInfo = (quote: QuoteHO3): DwellingInfo | undefined => {
  if (!quote.DwellingInfo) {
    return undefined;
  }

  const { AttachedScreenEnclosure, DetachedScreenEnclosure, Pool } =
    quote.DwellingInfo;

  const newAttachedScreenEnclosure: DetachedScreenEnclosure = {
    IsOnPremises: false,
  };
  if (AttachedScreenEnclosure) {
    const SizeSqFt = Number(AttachedScreenEnclosure.SizeSqFt);

    newAttachedScreenEnclosure.IsOnPremises =
      AttachedScreenEnclosure.IsOnPremises;
    newAttachedScreenEnclosure.BuiltOverPool =
      AttachedScreenEnclosure.BuiltOverPool;
    newAttachedScreenEnclosure.Height = AttachedScreenEnclosure.Height;
    newAttachedScreenEnclosure.SizeSqFt = SizeSqFt > 0 ? SizeSqFt : undefined;
  }
  const newDetachedScreenEnclosure: DetachedScreenEnclosure = {
    IsOnPremises: false,
  };
  if (DetachedScreenEnclosure) {
    const SizeSqFt = Number(DetachedScreenEnclosure.SizeSqFt);

    newDetachedScreenEnclosure.IsOnPremises =
      DetachedScreenEnclosure.IsOnPremises;
    newDetachedScreenEnclosure.BuiltOverPool =
      DetachedScreenEnclosure.BuiltOverPool;
    newDetachedScreenEnclosure.Height = DetachedScreenEnclosure.Height;
    newDetachedScreenEnclosure.SizeSqFt = SizeSqFt > 0 ? SizeSqFt : undefined;
  }

  return {
    AttachedScreenEnclosure: newAttachedScreenEnclosure,
    DetachedScreenEnclosure: newDetachedScreenEnclosure,
    Pool: Pool,
  };
};

const windMitFormMapping: {
  RoofCover: Record<string, string>;
  RoofDeckAttachment: Record<string, string>;
  RoofToWallConnection: Record<string, string>;
  RoofGeometry: Record<string, string>;
  SecondaryWaterResistance: Record<string, string>;
  OpeningProtection: Record<string, string>;
} = {
  RoofCover: {
    'A': 'FBC Equivalent',
    'B': 'FBC Equivalent',
    'C': 'Non-FBC Equivalent',
    'D': 'Non-FBC Equivalent',
    'Inspector Did Not Specify': 'Unknown',
    'Level B': 'FBC Equivalent',
    'Level A': 'Non-FBC Equivalent',
  },
  RoofDeckAttachment: {
    'A': `6d @ 6"/12`,
    'B': `8d @ 6"/12`,
    'C': `8d @ 6"/6" Dimensional Lumber Deck`,
    'D': 'Reinforced Concrete Roof Deck',
    'E': 'Unknown',
    'F': 'Unknown',
    'G': 'Unknown',
    'Inspector Did Not Specify': 'Unknown',
    'Level A': `6d @ 6"/12`,
    'Level B': `8d @ 6"/12`,
    'Level C': 'Reinforced Concrete Roof Deck',
  },
  RoofToWallConnection: {
    'A': 'Toe Nails',
    'B': 'Clips',
    'C': 'Single Wraps',
    'D': 'Double Wraps',
    'E': 'Reinforced Concrete Roof Deck',
    'F': 'Unknown',
    'G': 'Unknown',
    'H': 'Unknown',
    'Inspector Did Not Specify': 'N/A',
  },
  RoofGeometry: {
    'A': 'Hip',
    'B': 'Flat',
    'C': 'Gable',
    'Inspector Did Not Specify': 'Other',
  },
  SecondaryWaterResistance: {
    'A': 'Yes SWR',
    'B': 'No SWR',
    'C': 'Unknown',
    'Inspector Did Not Specify': 'Unknown',
    'Underlayment': 'Yes SWR',
    'Foamed Adhesive': 'No SWR',
  },
  OpeningProtection: {
    'A.1': 'Hurricane - Windows or All',
    'A.2': 'Hurricane - Windows or All',
    'A.3': 'Hurricane - Windows or All',
    'B.1': 'Basic - Windows or All',
    'B.2': 'Basic - Windows or All',
    'B.3': 'Basic - Windows or All',
    'C.1': 'None',
    'C.2': 'None',
    'C.3': 'None',
    'N.1': 'None',
    'N.2': 'None',
    'N.3': 'None',
    'X': 'None',
    'Inspector Did Not Specify': 'Unknown',
    'Class A (Hurricane Impact)': 'Hurricane - Windows or All',
    'Class B (Basic Impact)': 'Basic - Windows or All',
  },
};
