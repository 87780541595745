import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  BasicLayout,
  Card,
  Footer,
  Form,
  Error,
  MaskedInput,
} from 'src/common';
import { useAuth0, useForm, useModel } from 'src/lib/hooks';

type VerifyFormData = {
  Code: string;
};

const Verify = ({ layoutProps = {}, ...props }) => {
  const {
    user,
    verifyCode,
    handleVerifyCodeError,
    startLogin,
    handleStartLoginError,
  } = useAuth0();
  const [sending, setSending] = useState(false);
  const { control, handleSubmit, formState, formError, setFormError } = useForm(
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: async (props: any) => {
        const { err, setFormError, defaultOnError } = props;
        if (err?.error) {
          setFormError(handleVerifyCodeError(err));
        } else {
          await defaultOnError();
        }
      },
    }
  );
  const { Email } = useModel.quote();
  const history = useHistory();

  useEffect(() => {
    if (Email) {
      startLogin(Email);
    }
    // eslint-disable-next-line
  }, []);

  const onBack = () => {
    if (props.onBack) {
      props.onBack();
      return;
    }

    history.replace('/', { isHistorical: true });
  };

  const onSubmit = handleSubmit(async (data: VerifyFormData) => {
    await verifyCode(data.Code);
  });

  const resendCode = useCallback(async () => {
    setSending(true);
    try {
      await startLogin(user?.email);
    } catch (err) {
      setFormError(handleStartLoginError(err));
    } finally {
      setSending(false);
    }
  }, [user?.email, startLogin, handleStartLoginError, setFormError]);

  return (
    <BasicLayout {...layoutProps}>
      <Card
        icon="codeSafe"
        title="Enter your code."
        subtitle={
          <>
            <p>
              Check your email for the six-digit verification code
              <br className="hidden md:inline" /> we just sent you.
            </p>
          </>
        }
      >
        {formError && <Error>{formError}</Error>}
        <Form id="verification-code-form" onSubmit={onSubmit}>
          <MaskedInput
            label="Verification Code"
            name="Code"
            control={control}
            rules={{
              required: 'Verification code is required.',
              pattern: {
                value: /^[0-9]{6}$/,
                message: 'Verification code is required.',
              },
            }}
            mask="999999"
            containerClassName="sm:w-80 w-full mx-auto"
            autoComplete="one-time-code"
            error={control.getFieldError('Code')}
          />
        </Form>
        {user && (
          <div className="mt-4 text-center text-sm">
            Didn't receive the code?
            <button
              className="text-violet pl-1 disabled:text-dove-gray disabled:cursor-not-allowed underline"
              onClick={resendCode}
              disabled={formState.isSubmitting || sending}
            >
              {sending ? ' Sending...' : ' Resend!'}
            </button>
          </div>
        )}
      </Card>
      <Footer>
        <Footer.Next
          onClick={onSubmit}
          loading={formState.isSubmitting}
          label="Continue"
          blockScreenWhenLoading={false}
        />
        <Footer.Back onClick={onBack} disabled={formState.isSubmitting} />
      </Footer>
    </BasicLayout>
  );
};

export default Verify;
