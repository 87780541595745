import { useSelector, useDispatch } from 'react-redux';
import { Dispatch, RootState } from '../store';
import { Quote, RecursivePartial } from 'src/models/model';

interface DefaultsOptions {
  ignorePendingChanges?: (pendingChanges: Quote) => boolean;
  pageIndex?: number;
  furthestIndex?: number;
  forceDefaults?: boolean;
  useServerDefaultValues?: boolean;
  initialValues?: RecursivePartial<Quote>;
}

const defaults = (optionsParams: DefaultsOptions = {}) => {
  const options: DefaultsOptions = {
    ignorePendingChanges: () => false,
    ...optionsParams,
  };
  const { pageIndex, furthestIndex, pendingChanges } = useModel.session();
  const quote = useModel.quote();
  const isHistorical =
    (pageIndex ?? 0) < (furthestIndex ?? 0) &&
    !options.ignorePendingChanges?.(pendingChanges);

  if (isHistorical || options?.forceDefaults) {
    return pendingChanges;
  }
  if (options?.useServerDefaultValues) {
    return Object.assign({}, options?.initialValues, quote);
  }

  return Object.assign({}, options?.initialValues);
};

interface ModelHook<T, D> {
  (): T;
  dispatch: () => D;
  defaults: typeof defaults;
}

function createModelHook<K extends keyof RootState>(
  key: K
): ModelHook<RootState[K], Dispatch[K]> {
  const model = (() =>
    useSelector((state: RootState) => state[key])) as ModelHook<
    RootState[K],
    Dispatch[K]
  >;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  model.dispatch = () => useDispatch<Dispatch>()[key];
  model.defaults = defaults;

  return model;
}

const useModel = {
  currentPhase: createModelHook('currentPhase'),
  quote: createModelHook('quote'),
  session: createModelHook('session'),
  user: createModelHook('user'),
  updated: createModelHook('updated'),
  userInterface: createModelHook('userInterface'),
};

export default useModel;
