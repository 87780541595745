import React from 'react';
import { useHistory } from 'react-router-dom';

import { BasicLayout, Card, Footer } from 'src/common';

const UnsuportedState = () => {
  const history = useHistory();

  const handleGoBack = () => {
    history.push('/');
  };

  return (
    <BasicLayout displayNavElements={false}>
      <Card
        icon="seashells"
        title="Aw, shucks!"
        subtitle={
          'Slide Insurance is not available in your state yet, but we hope to have the opportunity to serve you in the future. Thank you for your interest in Slide!'
        }
      ></Card>
      <Footer>
        <Footer.Back
          rounded
          hideIcon={true}
          label="Exit"
          onClick={handleGoBack}
          className="w-105px"
        />
      </Footer>
    </BasicLayout>
  );
};

export default UnsuportedState;
