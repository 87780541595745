const _currentPhase = {
  state: {},
  reducers: {
    'quote/nextPhase': () => ({}),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    'appendCurrentPhase': (state: any, values: any) => ({
      ...state,
      ...values,
    }),
    'global/reset': () => ({}),
  },
};

export default _currentPhase;
