import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import persistPlugin from '@rematch/persist';
import updatedPlugin, { ExtraModelsFromUpdated } from '@rematch/updated';
import storage from 'redux-persist/lib/storage/session';

import models, { RootModel } from 'src/models';
import config from 'src/config';

type FullModel = ExtraModelsFromUpdated<RootModel>;

const store = init<RootModel, FullModel>({
  models,
  plugins: [
    persistPlugin({ key: 'root', storage, blacklist: ['user'] }),
    updatedPlugin(),
  ],
  redux: {
    devtoolOptions: {
      disabled: !config.debug,
      trace: config.debug,
      traceLimit: 25,
    },
  },
});

export type Store = typeof store;
export type RootState = RematchRootState<RootModel, FullModel>;
export type Dispatch = RematchDispatch<RootModel>;

export default store;
