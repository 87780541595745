import { useCallback } from 'react';
import auth0 from 'auth0-js';
import { useHistory, useLocation } from 'react-router-dom';

import config from 'src/config';
import { useModel } from 'src/lib/hooks';
import dayjs from 'dayjs';

const _webAuth = new auth0.WebAuth({
  ...config.auth0,
  redirectUri: `${window.location.origin}/login`,
  responseType: 'token id_token',
  scope: 'openid profile email',
});

const asyncWrapper = (func, options) =>
  new Promise((resolve, reject) =>
    func(options, (err, res) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(res);
    })
  );

const _checkSession = (options) =>
  asyncWrapper((...params) => _webAuth.checkSession(...params), options);

const _passwordlessStart = (options) =>
  asyncWrapper((...params) => _webAuth.passwordlessStart(...params), options);

const _passwordlessLogin = (options) =>
  asyncWrapper((...params) => _webAuth.passwordlessLogin(...params), options);

const _parseHash = (options) =>
  asyncWrapper((...params) => _webAuth.parseHash(...params), options);

const useAuth0 = () => {
  const history = useHistory();
  const user = useModel.user();
  const { save, signOut } = useModel.user.dispatch();
  const { pathname, search } = useLocation();

  const startLogin = async (email) => {
    save({ email, hasSession: false, loggingIn: true });

    sessionStorage.setItem('currentRoute', pathname + search);

    await _passwordlessStart({
      connection: 'email',
      send: 'code',
      email,
    });
  };

  const verifyCode = async (verificationCode) => {
    await _passwordlessLogin({
      connection: 'email',
      email: user.email,
      verificationCode,
    });
  };

  const getSessionTimeout = () => {
    // const sessionTimeout = jwtDecode(token)[
    //   'https://slideinsurance.com/session_timeout_ms'
    // ];
    return dayjs().add(config.sessionTime * 60, 's');
  };

  const completeLogin = async () => {
    const { idToken: token, expiresIn } = await _parseHash();
    const sessionTimeout = getSessionTimeout();

    save({ token, expiresIn, sessionTimeout });

    history.replace(sessionStorage.getItem('currentRoute'));
  };

  const logout = useCallback(
    (route = '/', state = {}) => {
      signOut();
      history.replace(route, state);
    },
    [history, signOut]
  );

  const refreshSession = async () => {
    await _checkSession({ responseType: 'token id_token' })
      .then(({ idToken: token, expiresIn }) => {
        const sessionTimeout = getSessionTimeout();
        save({ token, expiresIn, sessionTimeout });
      })
      .catch(() => {});
  };

  const handleStartLoginError = (err) => {
    if (err.statusCode === 503) {
      return 'Service is currently unavailable. Please try again later.';
    } else if (err.statusCode >= 500) {
      return 'Something went wrong. Please try again.';
    } else if (err.statusCode === 429) {
      return "We're experiencing a high volume of requests. Please try again in a few minutes.";
    } else {
      return 'Please check your email and try again.';
    }
  };

  const handleVerifyCodeError = (err) => {
    if (err?.error === 'password_leaked') {
      return err.description ?? err.error_description;
    } else if (
      err?.error === 'access_denied' &&
      err?.description?.includes('verification')
    ) {
      return 'The verification code was invalid.';
    } else if (
      err?.error === 'access_denied' &&
      err?.description?.includes('maximum')
    ) {
      return 'Maximum verification attempts exceeded. Please try resending a new code.';
    } else if (
      err?.error === 'access_denied' &&
      err?.description?.includes('expired')
    ) {
      return 'The verification code has expired.';
    } else if (err?.error === 'blocked_user') {
      return 'Your account has been blocked. Please contact support.';
    } else if (err?.error === 'too_many_attempts') {
      return err.error_description;
    } else {
      return 'Something went wrong. Please try again.';
    }
  };
  return {
    user,
    startLogin,
    handleStartLoginError,
    verifyCode,
    handleVerifyCodeError,
    completeLogin,
    refreshSession,
    logout,
  };
};

export default useAuth0;
