import Button from '../Button';

const NeedAssistance = () => (
  <div className="hidden flex-col z-50 mt-5 xl:fixed xl:top-20 xl:right-10 xl:w-1/5 xl:max-w-xs">
    <span className="text-violet mb-3 text-center xl:text-right">
      Need assistane? Call us - we're happy to help you with your quote!
    </span>
    <Button
      el="anchor"
      className="justify-center w-48 self-center xl:self-end"
      color="dark"
      href="tel:8005570964"
    >
      <span className="text-white font-heading">(800) 557-0964</span>
    </Button>
  </div>
);

export default NeedAssistance;
