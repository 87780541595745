import merge from 'lodash.merge';
import { UserInterface } from './model';

const _userInterface = {
  state: { isLoading: false },
  reducers: {
    'setInterface': (state: UserInterface, update: UserInterface) =>
      merge({}, state, update),
    'global/reset': () => ({ isLoading: false }),
  },
  effects: () => ({
    save: () => {},
  }),
};

export default _userInterface;
