import {
  useRef,
  useState,
  useEffect,
  PropsWithChildren,
  ReactNode,
} from 'react';
import {
  Tooltip as ReactTooltip,
  PlacesType as Place,
  TooltipRefProps,
} from 'react-tooltip';
import { Icon } from 'src/common';
import './Tooltip.css';
import useUId from 'src/lib/hooks/useUId';
import { twMerge } from 'src/lib/mergeTailwind';
import { IconColor, IconName, IconSize } from '../Icon/Icon';
import { useResponsiveVariant } from 'src/lib/hooks';

// const TOOLTIP_MAX_WIDTH = 320;

type TooltipProps = {
  label?: ReactNode;
  labelClassName?: string;
  title?: ReactNode;
  text?: ReactNode;
  className?: string;
  iconClassName?: string;
  place?: Place;
  icon?: IconName;
  size?: IconSize;
  color?: IconColor;
  hideTitle?: boolean;
  description?: string;
};

const Tooltip = ({
  icon = 'infoCircle',
  label = '',
  labelClassName = '',
  title = '',
  text = '',
  children = null,
  className = '',
  iconClassName = '',
  place = 'bottom',
  size,
  color = 'curious-blue',
  hideTitle = false,
  description = 'Click to open.',
}: PropsWithChildren<TooltipProps>) => {
  const labelId = useUId();
  const tooltipId = useUId();
  const btnRef = useRef<HTMLButtonElement | null>(null);
  const tooltipRef = useRef<TooltipRefProps | null>(null);
  const [open, setOpen] = useState(false);
  const isMobile = useResponsiveVariant({ default: true, sm: false });

  const handleOpen = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    if (btnRef.current) {
      btnRef.current.blur();
      setOpen(true);
    }
  };

  const handleClose = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    if (tooltipRef && tooltipRef.current) {
      // Why this? https://github.com/wwayne/react-tooltip/issues/449
      tooltipRef.current = null;
    }
    setOpen(false);
  };

  useEffect(() => {
    const onKeyup = (e: KeyboardEvent) => {
      if (open && e.key === 'Escape') {
        setOpen(false);
      }
    };
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, [open]);

  return (
    <div className={className}>
      <button
        type="button"
        data-event="click"
        data-tooltip-id={tooltipId}
        className={twMerge(
          'flex items-center text-curious-blue-dark focus:outline-none focus:ring-4 focus:ring-curious-blue-light',
          icon === 'infoCircle' && !children && 'rounded-full'
        )}
        aria-label={description}
        aria-controls={tooltipId}
        title={description}
        ref={btnRef}
        onClick={handleOpen}
        data-testid={`${tooltipId}-openTooltip`}
      >
        {children ? (
          <>
            {children}
            <Icon name={icon} color={color} size={size} />
          </>
        ) : (
          <p className={labelClassName}>
            {label}
            <span className="text-nowrap">
              &nbsp;
              <Icon
                className={twMerge(
                  'inline-block relative -top-1',
                  iconClassName
                )}
                name={icon}
                color={color}
                size={size}
              />
            </span>
          </p>
        )}
      </button>
      <div>
        <ReactTooltip
          id={tooltipId}
          data-tooltip-place={isMobile ? undefined : place}
          className={`z-50 bg-opacity-0 ${open ? 'show' : 'hidden'}`}
          isOpen={open}
          arrowColor="#fff"
          afterShow={() => {
            handleOpen();
          }}
          clickable
          ref={tooltipRef}
          role="dialog"
          aria-modal="true"
          aria-labelledby={labelId}
        >
          <div className="py-8 z-50 w-auto md:w-302px max-h-80 md:max-h-screen px-6 text-left rounded-lg bg-white mx-5 sm:mx-0 relative outline-none overflow-scroll md:overflow-hidden">
            <button
              type="button"
              className="absolute right-0 top-0 m-2.5 p-0.5 "
              onClick={() => {
                setOpen(false);
              }}
              data-testid={`${tooltipId}-closeTooltip`}
            >
              <Icon name="cancel" size="2sm" />
              <span className="sr-only">Close</span>
            </button>
            <h1
              data-testid={`${tooltipId}-tooltipTitle`}
              className={twMerge(
                'text-lg text-violet font-bold font-content leading-tight pb-2',
                hideTitle && 'sr-only'
              )}
              id={labelId}
            >
              {title}
            </h1>
            {typeof text === 'string' ? (
              <p
                data-testid={`${tooltipId}-tooltipText`}
                className="mt-2 text-violet text-sm font-normal"
              >
                {text}
              </p>
            ) : (
              <div
                data-testid={`${tooltipId}-tooltipText`}
                className="tooltip-content"
              >
                {text}
              </div>
            )}
          </div>
        </ReactTooltip>
        <button
          tabIndex={-1}
          aria-hidden="true"
          onClick={handleClose}
          className={twMerge(
            'fixed inset-0 w-screen h-screen bg-mystic z-40 transition outline-none cursor-default',
            open ? 'visible opacity-50' : 'invisible opacity-0 '
          )}
        />
      </div>
    </div>
  );
};

export default Tooltip;
