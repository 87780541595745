module.exports = {
  mode: 'jit',
  content: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
  theme: {
    extend: {
      gridTemplateColumns: {
        16: 'repeat(16, minmax(0, 1fr))',
        20: 'repeat(20, minmax(0, 1fr))',
        24: 'repeat(24, minmax(0, 1fr))',
      },
      gridColumn: {
        'span-13': 'span 13 / span 13',
        'span-14': 'span 14 / span 14',
        'span-15': 'span 15 / span 15',
        'span-16': 'span 16 / span 16',
        'span-17': 'span 17 / span 17',
        'span-18': 'span 18 / span 18',
        'span-19': 'span 19 / span 19',
        'span-20': 'span 20 / span 20',
        'span-21': 'span 21 / span 21',
        'span-22': 'span 22 / span 22',
        'span-23': 'span 23 / span 23',
        'span-24': 'span 24 / span 24',
      },
      gridColumnStart: {
        13: '13',
        14: '14',
        15: '15',
        16: '16',
        17: '17',
        18: '18',
        19: '19',
        20: '20',
        21: '21',
        22: '22',
        23: '23',
        24: '24',
      },
      gridColumnEnd: {
        13: '13',
        14: '14',
        15: '15',
        16: '16',
        17: '17',
        18: '18',
        19: '19',
        20: '20',
        21: '21',
        22: '22',
        23: '23',
        24: '24',
      },
      borderRadius: {
        '5px': '5px',
      },
      flex: {
        2: '2 2 0%',
        3: '3 3 0%',
      },
      maxHeight: {
        25: '25%',
        50: '50%',
        75: '75%',
      },
      fontSize: {
        '2xs': ['10px', '20px'], // Viewport footer
        'qgrades': ['12px', '18px'],
        '2sm': ['13px', '1.5'],
        'xbase': ['15px', '1.5'],
        '2lg': ['1.167rem', '1.75rem'], // blue headings contrast minimum
        '3xl': ['2rem', '3rem'], // tailwind 30px => custom 32px
        '4xl': ['2.625rem', '1'], // tailwind 36px => custom 42px
        '5xl': ['3.125rem', '1'], // tailwind 48px => custom 50px
      },
      fontFamily: {
        heading: ['Mulish', 'Helvetica', 'Arial', 'sans-serif'],
        content: ['Mulish', 'Helvetica', 'Arial', 'sans-serif'],
      },
      ringOpacity: {
        15: '0.15',
      },
      ringColor: {
        violet: '#E0E7FF',
      },
      stroke: {
        violet: '6648DC',
      },
      colors: {
        // https://chir.ag/projects/name-that-color/
        'violet': {
          lightest: '#6648DC1A',
          lighter: '#6648DC34',
          faded: '#6648DC80',
          light: '#6648DCBF',
          DEFAULT: 'rgba(102, 72, 220, 1)',
        },
        'description': {
          DEFAULT: '#5b564d',
        },
        'input': {
          light: '#6B7280',
          lighter: '#B4BCCE',
          background: '#F8F7FD',
          DEFAULT: '#2E2A3A',
        },
        'interview': {
          background: '#F8F7FD',
          background2: '#EAE8F9',
          background3: '#C4C7E8',
          scrollbar: '#C7CAEF',
        },
        'mystic': {
          light: '#DCE2EB7E',
          dark: '#bbc0c7',
          DEFAULT: '#DCE2EB',
        },
        'curious-blue': {
          light: '#DCD7F1',
          dark: '#5949A7',
          DEFAULT: '#6244BB',
        },
        'loading-background': {
          start: '#E8E4FA',
          middle: '#F8F7FD',
          end: '#FFFFFF',
        },
        'dove-gray': {
          lightest: '#70707080',
          light: '#707070BF',
          DEFAULT: '#707070',
        },
        'spindle': {
          DEFAULT: 'rgba(232, 228, 250, 1)',
        },
        'aqua-haze': {
          DEFAULT: '#EEF3F5',
        },
        'piper': {
          lighter: '#FCF7F6',
          light: '#FFE7BD',
          DEFAULT: '#C14E24',
        },
        'kournikova': {
          DEFAULT: '#FFDA74',
        },
        'custom-gray': {
          DEFAULT: '#EDF1F5',
        },
        'purple': {
          DEFAULT: '#E0DBFC',
        },
        'error': {
          dark: '#f5222d',
          light: '#F7E6E6',
          DEFAULT: '#E11D48',
        },
        'disabled': {
          default: '#EBEBE4',
          input: '#EFEFEF4D',
        },
      },
      inset: {
        '1/12': '8.333333%',
      },
      width: {
        '11.5': '2.625rem',
        '18': '4.5rem',
        '74': '18.5rem',
        '76': '19rem',
        '66.5': '16.625rem',
        '58px': '58px',
        '63px': '63px',
        '103px': '103px',
        '104px': '104px',
        '120px': '120px',
        '130px': '130px',
        '141px': '141px',
        '151px': '151px',
        '152px': '152px',
        '180px': '180px',
        '188px': '188px',
        '211px': '211px',
        '220px': '220px',
        '230px': '220px',
        '232px': '232px',
        '261px': '261px',
        '268px': '268px',
        '302px': '302px',
        '407px': '407px',
        '442px': '442px',
        '460px': '460px',
        '644px': '644px',
      },
      height: {
        '10.5': '2.625rem',
        '51px': '51px',
        '58px': '58px',
      },
      minHeight: {
        viewport: '95vh',
      },
      minWidth: {
        '1': '1rem',
        '1/6': '16.666667%',
        '1/5': '20%',
        '1/4': '25%',
        '1/3': '33.333333%',
        '1/2': '50%',
        '3/4': '75%',
      },
      maxWidth: {
        tiny: '12rem',
      },
      padding: {
        '17px': '17px',
        '18': '4.5rem',
      },
      ringWidth: {
        6: '6px',
      },
      screens: {
        xxxs: '319px',
        xs: '375px',
        tablet: '700px',
      },
      spacing: {
        '26': '6.5rem',
        '53px': '53px',
        '83px': '83px',
      },
      boxShadow: {
        mmd: '0px 4px 4px 0px #00000040',
        card: '0px 0px 4px 0px #00000040',
      },
      backgroundImage: (theme) => ({
        'circle-violet': `url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='rgb(79 70 229)' xmlns='http://www.w3.org/2000/svg'><circle cx='8' cy='8' r='9' /><circle cx='8' cy='8' r='3' fill='white'/></svg>")`,
        'check': `url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg' ><rect width='100%' height='100%' fill='rgba(102, 72, 220, 1)'/><path d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/></svg>")`,
        'check-disabled': `url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg' ><rect width='100%' height='100%' fill='rgba(102, 72, 220, 0.5)'/><path d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/></svg>")`,
      }),
    },
  },
  variants: {
    extend: {
      opacity: ['disabled'],
      cursor: ['disabled'],
      textColor: ['disabled'],
      margin: ['last'],
      backgroundImage: ['checked'],
      ringOffsetWidth: ['focus'],
    },
  },
  plugins: [],
};
